<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	export let light: boolean = false;
	export let useModalForLogin: boolean = false;

	const dispatch = createEventDispatcher();

	const open = (type: 'login' | 'register') => {
		dispatch('open', { type: type });
	};
</script>

<div
	class="{light
		? 'bg-white'
		: 'header--bg'} sticky inset-0 z-10 flex h-[63px] w-full flex-col pb-[6px] sm:h-[106px] sm:pb-[55px]"
>
	<div
		class="mx-auto mt-[7px] flex h-full w-full max-w-7xl flex-row items-end justify-between gap-x-1 gap-y-2 px-2 sm:mt-[47px] sm:px-4"
	>
		{#if light}
			<a href="/" title="Go back to home page">
				<img class="h-[49px] sm:h-[58px]" src="/logo-light.png" alt="logo nastia" />
			</a>
		{:else}
			<a href="/" title="Go back to home page">
				<img class="h-[49px] sm:h-[58px]" src="/logo.png" alt="logo nastia" />
			</a>
		{/if}

		<div class="buttons flex flex-row gap-4">
			{#if useModalForLogin}
				<button
					on:click|stopPropagation={() => open('login')}
					class="{light ? 'btn-secondary' : 'btn-secondary-dark'} hidden sm:block"
				>
					Log in
				</button>
				<button on:click|stopPropagation={() => open('register')} class="btn-primary-dark"
					>Chat now</button
				>
			{:else}
				<a
					href="/auth/login"
					class="{light ? 'btn-secondary' : 'btn-secondary-dark'} hidden sm:block"
				>
					Log in
				</a>
				<a href="/auth/register" class="btn-primary-dark">Chat now</a>
			{/if}
		</div>
	</div>
</div>

<style>
	.header--bg {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 33.21%, rgba(0, 0, 0, 0) 94.72%);
		backdrop-filter: blur(5px);
	}

	.sticky {
		position: -webkit-sticky;
		position: sticky;
	}
</style>
